import "./index.scss";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../store";

import Footer from "./Footer";
import Plans from "./Plans";

import logoVPN from "../../assets/logo-vpn4u.svg";

const PaywallPage = () => {
  const navigate = useNavigate();

  const { getProducts } = useStore();

  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("user");
  const currentDevice = searchParams.get("device");

  useEffect(() => {
    if (currentDevice) {
      localStorage.setItem("device", currentDevice);
    }

    if (email) {
      getProducts();
      localStorage.setItem("receiverEmail", email);
    } else {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="paywall-page">
        <header>
          <div
            className="paywall-logo"
            onClick={() => navigate("/")}
            style={{ margin: "0 auto" }}
          >
            <img src={logoVPN} alt="VPN logo" />
          </div>
        </header>
        <Plans email={email} />

        <Footer />
      </div>
    </>
  );
};

export default PaywallPage;
